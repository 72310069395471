// ==============================
// WINDOW EXTENSIONS

import { AgGridReact } from "ag-grid-react";

// ==============================
export interface DialogWindow extends Window {
  // Authentication & System Modals
  modalLogOut: any;
  modalWelcome: any;
  modalEditUser: any;
  modalSeasonNotAvailable: any;
  modalAdminRights: any;
  modalBoostLevelRights: any;
  sidebar: any;
  ENV: any;
  dataLayer: any;
  // Team Management Modals
  modalArchiveTeamConfirm: any;
  modalUnArchiveTeamConfirm: any;
  modalTeamIsArchived: any;
  modalTransferTeamConfirm: any;

  // Member Management Modals
  modalAddMember: any;
  modalEditMember: any;
  modalDeleteMember: any;
  modalInvitePreUser: any;
  modalEditPreUser: any;
  
  // Event Management Modals
  modalAddLocation: any;
  modalEditTraining: any;
  modalDeleteTraining: any;
  modalDeleteEvent: any;
  modalEditEvent: any;

  // Match Management Modals
  modalDeleteMatch: any;
  modalEditMatchScore: any;

  // Opponent Management Modals
  modalAddOpponent: any;
  modalDeleteOpponent: any;
  modalEditOpponent: any;
}

// ==============================
// CORE ENUMS
// ==============================
export enum ApiEnum {
  Web = "web",
  Mob = "mob",
}

export enum ScheduleTypEnum {
  training = "training",
  match = "match",
  teamCustomEvent = "teamCustomEvent",
}

export enum ScheduleTypeEnum {
  Training = "training",
  Match = "match",
  Event = "event",
}

export enum ScheduleOperationEnum {
  Edit = "edit",
  Create = "create",
}

export enum RsvpStatusEnum {
  Going = "Going",
  NoAnswer = "NoAnswer",
  NotGoing = "NotGoing",
}

/**
 * Type for Grid reference
 */
export type GridRefType = AgGridReact;

// ==============================
// USER & ACCOUNT TYPES
// ==============================
export enum GenderUserEnum {
  Male = "male",
  Female = "female",
  Other = "other",
}

export enum AccountRoleEnum {
  Player = "player",
  Trainer = "trainer",
  Support = "support",
  Fan = "fan",
}

export enum MemberRoleEnum {
  Player = "Player",
  Trainer = "Trainer",
  Support = "Support",
}

export enum MemberTeamManagementRoleEnum {
  admin = "admin",
  scheduler = "scheduler",
  scorekeeper = "scorekeeper",
  contentCreator = "contentCreator",
}

export enum PreferredPositionEnum {
  GK = "Goal keeper",
  CB = "Centreback",
  LB = "Leftback",
  RB = "Rightback",
  CDM = "Defensive Midfielder",
  CM = "Central Midfielder",
  LM = "Left Midfielder",
  RM = "Right Midfielder",
  CAM = "Attacking Midfielder",
  LW = "Left Winger",
  RW = "Right Winger",
  ST = "Striker",
  undefined = "undefined",
}

export type MemberPermissions = {
  admin: boolean;
  schedule: boolean;
  create: boolean;
  score: boolean;
};

export type ITeamRoles = {
  teamId: string;
  roles: MemberTeamManagementRoleEnum[];
};

export interface IUser {
  firstName: string;
  id: string;
  imageUrl: string;
  isPreUser: boolean;
  lastName: string;
  rsvpStatus: RsvpStatusEnum;
  notGoingReason: string;
  transportationStatus: string;
  email?: string;
  matchesPlayed: number;
  matchPlayed: boolean;
  preferredPosition?: PreferredPositionEnum;
  role: MemberRoleEnum;
  partcipatedTrainings?: string[];
  partcipatedTrainingsRank?: number;
  partcipatedMatches?: string[];
  partcipatedMatchesWithTimer?: string[];
  partcipatedMatchesRank?: number;
  average?: string;
  sessions?: string;
  goals?: string;
  matches?: string[];
  assists?: string[];
  goalsScoredRank?: number;
  assistsRank?: number;
  awards?: string[];
  awardsRank?: number;
  minutesPlayed: number;
  memberTotalMinutesPlayed: number;
  memberTotalDistanceCovered?: number;
  distanceCovered?: number;
  minutesPlayedRank?: number;
  permissions: MemberPermissions;
  selected?: boolean;
  scoredGoals: number;
  assistedGoals: number;
  warningCards: number;
  exitCards: number;
}

export interface AttendanceMember {
  displayName: string;
  firstName: string;
  lastName: string;
  isPreUser: boolean;
  role: MemberRoleEnum | string;
  id: string;
  profileImageUrl: string;
  rsvpStatus: RsvpStatusEnum;
  notGoingReason: string;
  transportationStatus: string;
  eventId?: string;
  eventType?: string;
  memberIsUpdated: boolean;
}

export type IUserLoggedIn = {
  id: string;
  email: string;
  birthday: string;
  gender: GenderUserEnum;
  isVerified: boolean;
  accountRole: AccountRoleEnum;
  profileComplete: boolean;
  profile: {
    firstName: string;
    lastName: string;
    privacy: string;
    imageUrl: string;
    preferredPosition: PreferredPositionEnum;
  };
  teamPermissions: ITeamRoles[];
} | null;

export type IUserAccount = {
  userId: string;
  token: string;
  refreshToken: string;
  tokenExpirationDate: string;
} | null;

export type claimUserData = {
  token?: string | undefined;
  teamId: string;
  preUserIdToClaim: string;
  context: any;
};

export interface IEditPreUser {
  userName: string;
  id: string;
  email?: string;
  sendInviteEmail: boolean;
}

export interface ICreatePreUser {
  userName: string;
  teamId: string;
  email?: string;
  sendInviteEmail: boolean;
}

// ==============================
// TEAM RELATED TYPES
// ==============================
export enum TeamGenderEnum {
  male = "male",
  female = "female",
  mixed = "mixed",
  undefined = "undefined",
}

export enum TeamGenderLabelEnum {
  male = "Male",
  female = "Female",
  mixed = "Mixed",
  undefined = "undefined",
}

export enum TeamLevelEnum {
  professional = "professional",
  semiProfessional = "semiProfessional",
  elite = "elite",
  performance = "performance",
  casual = "casual",
  undefined = "undefined",
}

export enum TeamLevelLabelEnum {
  professional = "Professional",
  semiProfessional = "Semi-Professional",
  elite = "Elite",
  performance = "Performance",
  casual = "Casual",
  undefined = "undefined",
}

export enum TeamAgeGroupEnum {
  u7AndYounger = "u7AndYounger",
  u8 = "u8",
  u9 = "u9",
  u10 = "u10",
  u11 = "u11",
  u12 = "u12",
  u13 = "u13",
  u14 = "u14",
  u15 = "u15",
  u16 = "u16",
  u17 = "u17",
  u18 = "u18",
  u19 = "u19",
  u20 = "u20",
  u21 = "u21",
  u22 = "u22",
  u23 = "u23",
  olderThanU23 = "olderThanU23",
  undefined = "undefined",
}

export enum TeamAgeGroupLabelEnum {
  u7AndYounger = "Under 7 and younger",
  u8 = "Under 8",
  u9 = "Under 9",
  u10 = "Under 10",
  u11 = "Under 11",
  u12 = "Under 12",
  u13 = "Under 13",
  u14 = "Under 14",
  u15 = "Under 15",
  u16 = "Under 16",
  u17 = "Under 17",
  u18 = "Under 18",
  u19 = "Under 19",
  u20 = "Under 20",
  u21 = "Under 21",
  u22 = "Under 22",
  u23 = "Under 23",
  olderThanU23 = "Older than 23",
}

export enum ITeamVisibilityEnum {
  visible = "visible",
  private = "private",
}

export enum PremiumLevel {
  Basic = "Basic",
  Pro = "Pro",
  Elite = "Elite",
}

interface ITeamBooster {
  userId: string;
  displayName: string;
  expiresAtUtc: string;
  willRenew: boolean;
}

export interface ITeam {
  id: string;
  name: string;
  nameAbbreviation: string;
  logoUrl: string;
  headerUrl: string;
  mingleForTeamsUrl: string;
  invite: {
    code: string;
    url: string;
    qrCodeUrl: string;
  };
  organization: IOrganization;
  leagueId: string;
  league: ILeague;
  followersCount: 0;
  membersCount: 0;
  players: IUser[];
  trainers: IUser[];
  support: IUser[];
  sportType: string;
  season: ISeason;
  isArchived: true;
  isTransfered: true;
  gender?: TeamGenderEnum;
  ageGroup?: TeamAgeGroupEnum;
  level?: TeamLevelEnum;
  visibilityLevel: ITeamVisibilityEnum;
  address?: IAddress;
  socials?: {
    twitter?: string;
    instagram?: string;
    tikTok?: string;
    youTube?: string;
  };
  boostStatus?: {
    boostLevel: number;
    teamBoosters: ITeamBooster[];
  };
}

interface ITeamOLD {
  id: string;
  name: string;
  leagueId: string;
  fullName: string;
  shortName: string | null;
  inviteCode: string;
  sportType: string;
  isTransfered: boolean;
  isArchived: boolean;
  inviteUrl: string;
  inviteQrCodeUrl: string;
  gender?: TeamGenderEnum;
  ageGroup?: TeamAgeGroupEnum;
  level?: TeamLevelEnum;
  boostStatus?: {
    boostLevel: number;
    teamBoosters: ITeamBooster[];
  };
  visibilityLevel: ITeamVisibilityEnum;
  headerUrl?: string;
  followersCount?: number;

  invite?: {
    code: string;
    url: string;
    qrCodeUrl: string;
  };

  logoUrl: string;
  season: ISeason;
  nameAbbreviation: string;
  organizationId: string | null;
  defaultLocationId: string | null;
  organization: IOrganization;
  league: ILeagueTeam;

  players: IUser[];
  trainers: IUser[];
  support: IUser[];
  totalMatches: number;
}

export type ITeamAdd = {
  sportType: string;
  leagueId?: string;
  organizationId?: string;
  name: string;
  gender: string;
  ageGroup: string;
  level: string;
  logoFileName?: string;
  userRole: string;
  timezone: string;
};

export type ITeamMatchSettings = {
  teamId: string;
  formationAmount: string;
  periodSettings: {
    minutesPerPeriod: number;
    numberOfPeriods: number;
  };
  lineupSettings: {
    formation: FormationEnum;
  };
};

export interface ITeamGender {
  value: string;
  displayValue: string;
  teamNameAffix: string;
  teamNameAffixOrder: string;
}

export interface ITeamAgeGroup {
  value: string;
  displayValue: string;
  teamNameAffix: string;
  teamNameAffixOrder: string;
}

export interface IOnboardingData {
  teamGender?: TeamGenderEnum;
  teamAgeGroup?: TeamAgeGroupEnum;
  teamLevel?: TeamLevelEnum;
  isSingleOrganization?: boolean;
  teamMatchSettings?: ITeamMatchSettings;
  league?: ILeague;
  organization?: IOrganization;
  teamCreated?: ITeam;
  trainingHowOften?: {
    howOftenPeriod: string;
    howOften: RecurringEnum;
  };
}

// ==============================
// ORGANIZATION & LOCATION TYPES
// ==============================
export interface IAddress {
  line1?: string;
  line2?: string;
  zipCode?: string;
  city?: string;
  region?: string;
  country?: string;
  mapImageUrl?: string;
  coordinates?: {
    lat?: number;
    lon?: number;
  };
}

export interface IOrganization {
  id: string;
  name: string;
  countryCode?: string;
  shortName: string;
  logoUrl: string;
  type?: string;
  isVerified?: boolean;
  address?: IAddress;
}

export interface ILocation {
  city: string;
  coordinates: { lat: number; lon: number };
  id: string;
  line1: string;
  line2: string;
  mapImageUrl: string | null;
  organizationId: string;
  region: string;
  teamId: string;
  zipCode: string;
}

export interface ICountry {
  name: string;
  code: string;
}

export enum RegionTypeEnum {
  "Drenthe",
  "Flevoland",
  "Friesland",
  "Gelderland",
  "Groningen",
  "Limburg",
  "Noord-Brabant",
  "Noord-Holland",
  "Overijssel",
  "Utrecht",
  "Zeeland",
}

// ==============================
// LEAGUE & SEASON TYPES
// ==============================
export interface ISeason {
  endsAtUtc: string;
  id: string;
  isActive: boolean;
  name: string;
  startsAtUtc: string;
}

export interface ILeagueTeam {
  id: string;
  name: string;
  isDefault: boolean;
  logoUrl: string;
  countryCode: string;
  seasons?: ISeason[];
}

export interface ILeague {
  id: string;
  name: string;
  shortName: string;
  logoUrl: string;
  sportType: string;
  countryCode: string;
  isHighlighted: boolean;
  isDefault: boolean;
  defaultOrganizationId: string;
  seasons: ISeason[];
}

export interface ILeaguesData {
  leagues: ILeague[];
  defaultLeague: ILeague;
  page: number;
  total: number;
}

// ==============================
// SCHEDULE & EVENT TYPES
// ==============================
export interface ISchedule {
  teamId: string;
  scheduledAtUtc: string;
  data: IScheduleData;
}

export interface IScheduleData {
  id: string;
  groupId?: string;
  title?: string;
  startAtUtc?: string;
  duration?: string;
  recurring?: string;
  canReplyRsvp: boolean;
  type: ScheduleTypEnum;
  team: ITeam;
  rsvps: string[];
  address?: string[];
  matchFeedId?: string;
  opponent?: IOpponent;
  scheduledAtUtc?: string;
  arrivalAtUtc?: string;
  isAway?: boolean;
  cancellationStatus?: string[];
}

// ==============================
// TRAINING TYPES
// ==============================
export enum TrainingTypeEnum {
  single = "single",
  series = "series",
}

export enum TrainingNotGoingReasonEnum {
  undefined = "No reason",
  sick = "Sick",
  injured = "Injured",
  vacation = "Vacation",
  schoolOrWork = "School Or Work",
  other = "Other",
}

export enum DurationEnum {
  thirtyMinutes = "30m",
  fourtyFiveMinutes = "45m",
  oneHour = "1h",
  oneHalfHour = "1.5h",
  twoHours = "2h",
}

export enum RecurringEnum {
  Never = "Never",
  Week = "Week",
  TwoWeeks = "2Weeks",
  Month = "Month",
}

export enum RecurringEnumlabel {
  Never = "Not recurring",
  Week = "Weekly",
  TwoWeeks = "Every 2 Weeks",
  Month = "Monthly",
}

export interface ITraining {
  id: string;
  groupId: string;
  title: string;
  startAtUtc: string;
  duration: string;
  recurring: string;
  address: IAddress;
  team: {
    id: string;
    name: string;
    nameAbbreviation: string;
    logoUrl: string;
    organization: IOrganization;
  };
}

export interface ITrainingAttendance {
  startAtUtc: string;
  id: string;
  members: AttendanceMember[];
  attendedMembers: AttendanceMember[];
  attendedMembersCount: number;
  attendedPercentage: number;
}

// ==============================
// MATCH & GAME TYPES
// ==============================
export enum EventTypeEnum {
  single = "single",
  series = "series",
}

export enum MatchTypeEnum {
  League = "league",
  Playoff = "playoff",
  Cup = "cup",
  PreSeason = "preSeason",
  Friendly = "friendly",
}

export enum FormationEnum {
  "11-433" = "Football-11-433",
  "11-442" = "Football-11-442",
  "11-352" = "Football-11-352",
  "11-451" = "Football-11-451",
  "11-4231" = "Football-11-4231",
  "11-532" = "Football-11-532",
  "11-541" = "Football-11-541",
  "11-343" = "Football-11-343",
  "9-233" = "Football-9-233",
  "9-242" = "Football-9-242",
  "9-323" = "Football-9-323",
  "9-332" = "Football-9-332",
  "9-341" = "Football-9-341",
  "9-422" = "Football-9-422",
  "9-431" = "Football-9-431",
  "8-232" = "Football-8-232",
  "8-241" = "Football-8-241",
  "8-322" = "Football-8-322",
  "8-331" = "Football-8-331",
  "7-132" = "Football-7-132",
  "7-2121" = "Football-7-2121",
  "7-222" = "Football-7-222",
  "7-231" = "Football-7-231",
  "7-312" = "Football-7-312",
  "6-131" = "Football-6-131",
  "6-212" = "Football-6-212",
  "6-221" = "Football-6-221",
  "6-311" = "Football-6-311",
  "5-121" = "Football-5-121",
  "5-211" = "Football-5-211",
  "5-22" = "Football-5-22",
}

export interface IOpponent {
  id: string;
  name: string;
  nameAbbreviation: string;
  logoUrl: string;
  address: IAddress;
  organization?: IOrganization;
}

export interface IGoal {
  id: string;
  assistant: string;
  scorer: string;
  scoredAtUtc: string;
}

export interface IScore {
  team: number;
  opponent: number;
}

export interface IMessageToDelete {
  matchFeedId: string;
  messageId: string;
}

export interface IGoalUpdateAdd {
  matchFeedId: string;
  messageId: string;
  matchPeriod?: number;
  matchMinute?: number;
  sentAtUtc: string;
  teamId: string;
  scorerId: string;
  assistantId: string;
}

export interface IMatchHistoryData {
  type: string;
  score: IScore;
  player: IUser | null;
  assistant: IUser | null;
  isOpponent: boolean;
  teamId: string;
}

export interface IMatchEventMessage {
  id: string;
  sender?: IUser;
  type: string;
  actionType: string;
  sentAtUtc: string;
  matchPeriod?: number;
  matchMinute?: number;
  reactions?: { cheers: any[]; comments: number };
  feedVersion?: number;
  data: IMatchHistoryData;
  createdLive?: boolean;
  updateStatus?: string;
}

export interface IRsvp {
  id: string;
  rsvpStatus: string;
  role: string;
  user: IUser;
  note: string;
  reminded: boolean;
  notGoingReason: string;
  transportationStatus: string;
  createdAtUtc: string;
}

export interface IMatchStats {
  scheduledAtUtc: string;
  team?: ITeam;
  members: IUser[];
  players: IUser[];
  rsvps?: IRsvp[];
  attendedPlayers: IUser[];
  attendedPlayersCount: number;
  attendedPercentage: number;
  viewsCount: number;
  totalComments: number;
  totalCheers: number;
  editIsLocked: boolean;
  goals: IGoal[];
  id: string;
  isAway: boolean;
  opponent: ITeam;
  isStarted: boolean;
  isFinished: boolean;
  score: IScore;
  votingResults: {
    averageMatchRating: number;
    mvpWinner: string;
    effortWinner: string;
    creativeWinner: string;
  };
  matchSettings: {
    periodSettings: { minutesPerPeriod: number; numberOfPeriods: number };
  };
}

export interface IMatch {
  id: string;
  matchFeedId: string;
  team: ITeam;
  score: IScore;
  opponent: ITeam;
  isAway: boolean;
  scheduledAtUtc: string;
  arrivalTimeUtc: string;
  arrivalLocation: string;
  location: string;
  goalsScored: number;
  goalsConceded: number;
  scoreStatus: string;
  viewsCount: number;
  totalCheers: number;
  totalComments: number;
  totalPlayers: number;
  playersAttended: number;
  mostEngagingPictureUrl: string;
  mostEngagingVideoUrl: string | null;
  mvpWon: string | null;
  workhorseWon: string | null;
  playmakerWon: string | null;
  address: IAddress;
}

export type MatchCreateBody = {
  teamId: string;
  type?: MatchTypeEnum;
  opponentId: string;
  isAway: boolean;
  scheduledAtUtc: string;
  arrivalTimeUtc: string;
  arrivalLocation: string;
  address?: IAddress;
  saveAddressAsHomeTeamDefault?: boolean;
};
``;

export type OpponentData = {
  opponents: IOpponent[];
  organizations: IOrganization[] | undefined;
};

export type OpponentAddBody = {
  id: string;
  name: string;
  logoUrl?: string;
  teamId: string;
  organizationId: string;
};

// ==============================
// STATISTICS & PERFORMANCE TYPES
// ==============================
export interface ITotals {
  totalMinutesPlayed: number;
  totalMatchMinutes: number;
  totalDistanceCovered: number;
  totalGoals: number;
  totalAssists: number;
  totalAwards: number;
  totalViews: number;
  totalEngagement: number;
  averageMatchRating: string;
  totalScored: number;
  totalConceded: number;
  totalMatchesWon: number;
  totalMatchesLost: number;
  totalMatchesDraw: number;
  totalCleanSheet: number;
  goalDifference: number;
}

export interface IShotData {
  attemptId: string;
  downloadableVideoUrl: string;
  resultStatus: string;
  round: number;
  score: {
    accuracy: number;
    points: number;
    speed: number;
  };
  sessionCreatedAtUtc: string;
  sessionName: string;
  sessionPlayer: {
    id: string;
    imageUrl: string;
    username: string;
  };
  status: string;
  videoUrl: string;
}

export interface IConfiguration {
  ageGroup: string;
  discriminator: string;
  gender: string;
  number: number;
}

// ==============================
// PAGE DATA TYPES
// ==============================
export type TeamPagesData = {
  loggedInUser?: IUserLoggedIn;
  user: IUserAccount | null;
  activeTeam: ITeam;
  matches: IMatchStats[] | null;
  futureMatches: IMatchStats[] | null;
  teams: ITeam[] | null;
  showWelcomeMessage?: boolean;
  ENVIRONMENT?: string;
  totals?: ITotals;
  matchStatsPlayers?: IUser[];
  opponents?: IOpponent[];
};

export type TeamTrainingPagesData = {
  loggedInUser?: IUserLoggedIn;
  activeTeam: ITeam;
  matches: IMatchStats[] | null;
  trainingsStatsPast: ITrainingAttendance[] | null;
  trainingsStatsFuture: ITrainingAttendance[] | null;
  trainingsScheduledFuture: ITraining[] | null;
  trainingsScheduledPast: ITraining[] | null;
  trainingStatsPlayers?: IUser[];
};

export type onBoardingUser = {
  userLoggedIn: IUserLoggedIn;
};
